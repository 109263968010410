html,
body,
#root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.main_wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #4e73df;
}

.wrapper {
  width: 50%;
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

.notification {
  position: absolute;
  top: -220px;
  left: calc(50% - 170px);
  width: 300px;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
}
