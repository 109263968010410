@font-face {
	font-family: Averta;
	font-style: normal;
	font-display: swap;
	font-weight: 400;
	src: url('./fonts/averta-regular-webfont.woff') format('woff');
}
@font-face {
	font-family: Averta;
	font-style: normal;
	font-display: swap;
	font-weight: 600;
	src: url('./fonts/averta-semibold-webfont.woff') format('woff');
}
@font-face {
	font-family: Averta;
	font-style: normal;
	font-display: swap;
	font-weight: bold;
	src: url('./fonts/averta-bold-webfont.woff') format('woff');
}
